// Generated by Framer (8a81902)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["YnXfrsvOD", "gWsTOWKop"];

const variantClassNames = {gWsTOWKop: "framer-v-1regx3z", YnXfrsvOD: "framer-v-plqq6c"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {default: "YnXfrsvOD", flip: "gWsTOWKop"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "YnXfrsvOD"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "YnXfrsvOD", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter189mj6n = activeVariantCallback(async (...args) => {
setVariant("gWsTOWKop")
})

const onMouseLeavev980o4 = activeVariantCallback(async (...args) => {
setVariant("YnXfrsvOD")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Sd0fv", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-plqq6c", className)} data-framer-name={"default"} data-highlight layoutDependency={layoutDependency} layoutId={"YnXfrsvOD"} onMouseEnter={onMouseEnter189mj6n} ref={ref} style={{...style}} {...addPropertyOverrides({gWsTOWKop: {"data-framer-name": "flip", onMouseEnter: undefined, onMouseLeave: onMouseLeavev980o4}}, baseVariant, gestureVariant)}><motion.div className={"framer-1ll8hyj"} data-border layoutDependency={layoutDependency} layoutId={"hKbR3lxVH"} style={{"--border-bottom-width": "1px", "--border-color": "rgba(255, 255, 255, 0.25)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 201, borderBottomRightRadius: 201, borderTopLeftRadius: 201, borderTopRightRadius: 201}}/><motion.div className={"framer-1xqyoqc"} layoutDependency={layoutDependency} layoutId={"ONqnR85DY"} style={{backdropFilter: "blur(4px)", backgroundColor: "rgba(255, 255, 255, 0.25)", rotate: 0, WebkitBackdropFilter: "blur(4px)"}} variants={{gWsTOWKop: {rotate: 180}}}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Sd0fv [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Sd0fv .framer-bdjpwd { display: block; }", ".framer-Sd0fv .framer-plqq6c { height: 36px; overflow: visible; position: relative; width: 36px; }", ".framer-Sd0fv .framer-1ll8hyj { bottom: -12px; flex: none; height: 24px; overflow: hidden; position: absolute; right: -12px; width: 24px; will-change: var(--framer-will-change-override, transform); }", ".framer-Sd0fv .framer-1xqyoqc { bottom: 0px; flex: none; height: 1px; left: 0px; overflow: hidden; position: absolute; right: -36px; }", ".framer-Sd0fv.framer-v-1regx3z .framer-plqq6c { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 36px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 36
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"gWsTOWKop":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const Framerpi4U9AjvC: React.ComponentType<Props> = withCSS(Component, css, "framer-Sd0fv") as typeof Component;
export default Framerpi4U9AjvC;

Framerpi4U9AjvC.displayName = "aim";

Framerpi4U9AjvC.defaultProps = {height: 36, width: 36};

addPropertyControls(Framerpi4U9AjvC, {variant: {options: ["YnXfrsvOD", "gWsTOWKop"], optionTitles: ["default", "flip"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerpi4U9AjvC, [])